<template>
  <!-- 头条新增模板2.0版本 -->
  <div class="paddbox toutiaoAddModel">
    <div class="toutiaoAddModelTitle">
      <el-card>
        <div class="toutiaoAddModelTitleTop flex">
          <div>
            <el-button
              type="primary"
              :disabled="openSonviewAble"
              @click="upAccountFun()"
              >{{
                openSonviewAble == false ? '请选择子账户' : '已选择完成'
              }}</el-button
            >
          </div>
          <div>
            <div class="title">
              <el-tooltip
                class="item"
                effect="dark"
                :content="sonString"
                placement="right"
              >
                <div>已选:({{ SonAccountData.length }})</div>
              </el-tooltip>
            </div>
          </div>
          <!-- <div>
            <selectSonAccount
              :data="selectSonAccountData"
            ></selectSonAccount>
          </div> -->
          <div>
            <el-button type="danger" round @click="ResetPageFun()"
              >重置</el-button
            >
          </div>
        </div>
      </el-card>
    </div>
    <div class="toutiaoAddModelcontent">
      <el-card>
        <div class="toutiaoAddModel-cardbox ">
          <el-row :gutter="20">
            <!-- 项目配置 -->
            <el-col :span="12"
              ><div class="grid-content ep-bg-purple" />
              <!-- 项目配置模块开始 -->
              <div class="toutiaoAddModel-carditem">
                <div class="defineHead">
                  <span>项目配置 </span>
                  <span @click="AdPlanFun"
                    ><i
                      title="编辑"
                      class="cursor editIcon el-icon-edit disabled"
                    ></i
                  ></span>
                </div>
                <div class="defineBody">
                  <div class="settingBox">
                    <span class="s_label">推广目标:</span>
                    <span class="s_text">销售线索收集</span>
                  </div>
                  <div class="settingBox">
                    <span class="s_label">营销场景:</span>
                    <span class="s_text">短视频 + 图文</span>
                  </div>
                  <div class="settingBox">
                    <span class="s_label">广告组类型:</span>
                    <span class="s_text">所有广告</span>
                  </div>
                </div>
              </div></el-col
            >
            <!-- 创意配置 -->
            <el-col :span="12"
              ><div class="grid-content ep-bg-purple" />
              <!-- 创意配置模块开始 -->
              <div class="toutiaoAddModel-carditem">
                <div class="defineHead">
                  <span>创意配置 </span>
                  <span @click="LdeasSetFun"
                    ><i
                      title="编辑"
                      class="cursor editIcon el-icon-edit disabled"
                    ></i
                  ></span>
                </div>
                <div class="defineBody">
                  <div class="mandatory">
                    <div class="settingBox">
                      <!-- <span class="s_label">计划名称:</span>
                      <span class="s_text">{{ adDto.name }}</span> -->
                    </div>
                  </div>
                </div>
              </div></el-col
            >
          </el-row>
        </div>
        <!-- 按钮 -->
        <div class="toutiaoAddModelBtn flex">
          <div>

          <el-button
            type="button"
            @click="setTimeAbleFun()"
            class="el-button el-button--default el-button--small"
          ><span> 定时发布 </span>
          </el-button>
          </div>
          <div>
            <el-button
            type="button"
            @click="createPlanFun()"
            :disabled="buttonDisabled"
            ref="myButton"
            class="el-button el-button--default el-button--small"
          >
            <!----><!----><span> 保存并去发布 </span>
          </el-button></div>

        </div>

      </el-card>
    </div>
    <!-- 选择子账户弹窗 -->
    <el-dialog v-model="openSonview">
      <insertView
        @getAccountListFun="getSonAccountListFun"
        @getSelectSonAccountData="getSelectSonAccountData"
        @getCannelFun="getCannelFun"
      ></insertView>
    </el-dialog>
    <!-- 项目配置 -->
    <el-drawer size="60%" v-model="AdPlanOn" title="项目配置">
      <toutiaoprojectSetting2
        :toutiaoMedia="toutiaoMedia"
        :optionsData="optionsData"
        :getV2optimizedGoalData="getV2optimizedGoalData"
        :SonAccountDataNew="SonAccountDataNew"
        :SonAccountDataNew3="SonAccountDataNew3"
        :SonAccountDataNew4="SonAccountDataNew4"
        :SonAccountDataNew5="SonAccountDataNew5"
        @campaignDto="campaignDto"
        @updateOptions="updateOptions"
        :getProductAvailablesData="getProductAvailablesData"
      ></toutiaoprojectSetting2>
    </el-drawer>

    <!-- 创意配置 -->
    <el-drawer size="70%" v-model="LdeasSetOn" title="创意配置">
      <toutiaLdeasSet2
        :SonAccountDataNew="SonAccountDataNew2"
        :selectGroupTitleArr="selectGroupTitleArr"
        :selectIndustryArr="selectIndustryArr"
        @flushTitleFun="flushTitleFun"
        @creativeDtoFun="creativeDtoFun"
        :SonAccountData="SonAccountData"
        :SonAccountModelData="SonAccountModelData"
        @getSonAccountFun="getSonAccountFun"
        @productOkFun="productOkFun"
        :dialogFormVisible="dialogFormVisible"
        :campaignDtoDataSmartBidType="campaignDtoDataSmartBidType"
        :budgetOptimizeSwitch="budgetOptimizeSwitch"
        :deliveryData="deliveryData"
      ></toutiaLdeasSet2>
    </el-drawer>

    <!-- 定时发布的弹窗 -->
    <el-dialog v-model="setTimeAble">
      <div>
        <div>定时发布</div>
        <el-divider></el-divider>
        <el-form label-width="140px" class="demo-ruleForm">
          <el-form-item label="备注：" >
            <el-input placeholder="请输入备注" v-model="name" clearable> </el-input>
          </el-form-item>
          <el-form-item label="设定的时间：" >
            <el-date-picker
              v-model="sTime"
              value-format="YYYY-MM-DD HH:mm:ss"
              :default-value="new Date()"
              type="datetime"
              placeholder="请选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div class="addTitleBtn flex">
          <div>
            <el-button
              type="warning"
              plain
              @click="setTimeAbleFun()"
              >取消</el-button
            >
          </div>
          <div>
            <el-button type="primary" @click="scheduleCreateV2PlanFun()"
              >确定</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import selectSonAccount from '@/components/batch/selectSonAccount.vue'
import { ElMessage } from 'element-plus'
import { inject, reactive, toRefs } from 'vue'
import toutiaoAdsSetDrawer from '../toutiaoComponents/toutiaoAdsSetDrawer.vue'
import toutiaoprojectSetting2 from '../toutiaoComponents/toutiaoprojectSetting2.vue'
import toutiaoAdWebDrawer from '../toutiaoComponents/toutiaoAdWebDrawer.vue'
import toutiaLdeasSet2 from '../toutiaoComponents/toutiaLdeasSet2.vue'
import insertView from '@/components/batch/insertView.vue'
import {
  selectDirectionalNotPage,
  getV2optimizedGoal,
  selectGroupTitle,
  createV2Plan,
  selectToutiaoMedia,
  scheduleCreateV2Plan,
} from '@/request/new/videoBatchManagement'
export default {
  components: {
    toutiaoAdsSetDrawer,
    toutiaoprojectSetting2,
    toutiaoAdWebDrawer,
    selectSonAccount,
    toutiaLdeasSet2,
    insertView,
  },

  name: 'toutiaoAddModel2',
  setup() {
    const state = reactive({
      //定时的时间
      sTime:'',
      //定时备注
      name:'',
      //定时发布的弹窗
      setTimeAble:false,
      //按钮不可用
      buttonDisabled: false,
      SonAccountDataNew3: [],
      //字节小程序数据
      SonAccountDataNew4: [],
      //微信小程序数据
      SonAccountDataNew5: [],      
      budgetOptimizeSwitch: '',
      //要从项目里面传入创意的数据
      deliveryData: {},
      // 打开选择子账户的弹窗
      openSonview: false,
      // 选择子账户的按钮是否可用
      openSonviewAble: false,
      // 子账户的字符串
      sonString: '',
      getProductAvailablesData: '',
      toutiaoMedia: [],
      selectGroupTitleArr: [],
      // 优化目标
      getV2optimizedGoalData: '',
      // 传入选择子账户组件中说明是什么类型
      selectSonAccountData: {
        type: 'toutiao',
      },
      // 选中的子账户数据
      SonAccountData: [],
      // 转化、落地页配置
      webTableData: [],
      // 请求获取定向包数据
      optionsData: [],
      // 新的子账号数据
      SonAccountDataNew: [],
      SonAccountDataNew2: [],
      campaignDtoDataSmartBidType: '',
      // 接收选择子账户组件的值
      getSelectSonAccountData: (val) => {},
      // 项目配置返回的值
      campaignDto: (val) => {
        state.campaignDtoData = val
        state.AdPlanOn = false
        // state.LdeasSetOn = true


        //根据项目的内容传递要插入的参数
        state.deliveryData = {
          isVersion: state.campaignDtoData.isVersion,
          landingType:state.campaignDtoData.landingType,
          microPromotionType :state.campaignDtoData.microPromotionType,
          isIaaProject:state.campaignDtoData.isIaaProject,
          deepExternalAction:state.campaignDtoData.deepExternalAction,
          bidState:state.campaignDtoData.bidState

        }
        console.log("🚀 ~ setup ~ state.deliveryData:根据项目的内容传递要插入的参数", state.deliveryData)
        
      },
      //定时发布的弹窗方法
      setTimeAbleFun:()=>{
        state.setTimeAble=!state.setTimeAble
      },
      // 刷新定向包列表
      updateOptions: () => {
        selectDirectionalNotPageFun()
      },
      //  开关-----------
      LdeasSetOn: false,
      // 修改打开选择子账户的弹窗
      upAccountFun: () => {
        state.openSonview = !state.openSonview
      },
      // 接收创意配置数据
      creativeDtoFun: (val) => {
        state.creativeDto = val

        state.LdeasSetOn = false
      },
      // 选择子账户的按钮值的变化
      openSonviewAbleFun: () => {
        state.openSonviewAble = !state.openSonviewAble
      },
      // 接收组件的取消
      getCannelFun: () => {
        state.upAccountFun()
      },

      // 接收组件的确定
      getSonAccountListFun: (data) => {
        state.SonAccountData = data
        //  state.SonAccountData = val
        state.SonAccountData.forEach((item) => {
          state.SonAccountDataNew.push({ advertiserId: item, value: '' })
          //微信小程序数据
          state.SonAccountDataNew5.push({
            advertiserId: item,
            //微信小程序数据集合
            getWechatAppletListData:[],
            //微信小程序资产id
            instanceId: '',
            //微信小程序名称
            name:'',
          })
          //字节小程序数据
          state.SonAccountDataNew4.push({
            advertiserId: item,
            //字节小程序数据集合
            getV2MicroAppData: [],
            //微信小程序数据集合
            getWechatAppletListData:[],
            //小程序资产id
            instanceId: '',
            //事件资产数据
            assetIdData: [],
            assetId: '',
            //选中的小程序链接
            list: [],
            //选中链接
            startParam: '',
          })
          state.SonAccountDataNew3.push({
            advertiserId: item,
            platformId: '',
            getProductAvailablesData: [],
            productId: '',
            getProductDetailData: [],
            //AIGC动态创意
            aigcDynamicCreativeSwitch:'OFF',
          })
          state.SonAccountDataNew2.push({
            advertiserId: item,
            value: '',
            list: [],
            nativeSettings: 'douyin',
            anchorRelatedType: 'OFF',
            awemeId: '',
            anchorId: '',
            writerList: [{ val: '' }],
            selling_points: '',
          })
          state.webTableData.push({
            accountName: item,
            webData: [],
            checked: '',
            // 选中的短剧优化目标
            targeted: '',
            // 选中的短剧优化目标名称
            targetedName: '',
            targetData: [],
            // 事件管理的参数
            landPageId: '',
            asset_ids: [],
            asset_idsName: '',
            external_action: '',
            external_actionName: '',
            action_track_url: '',
            // 资产数据id
            assetData: [],
            // 资产下拉框
            assetDataList: [],
            // 直达链接
            open_url: '',
            // 事件名称的数据
            eventNameDate: [],
            // 选中的事件名称
            checkEvent: '',
            // 电商优化目标的数据
            commodityDate: [],
            // 选中的电商优化目标
            checkCommodity: '',
            // 选中的电商优化目标名称
            checkCommodityName: '',
          })
        })
        console.log(state.SonAccountDataNew, '2')
        state.openSonviewAbleFun()
        console.log('变化', state.openSonviewAble)
        data.forEach((item, index) => {
          if (data.length - 1 == index) {
            state.sonString += item
          } else {
            state.sonString += item + '，'
          }
        })
        state.upAccountFun()
        console.log('而非', state.SonAccountData)
      },
      // 创意配置
      LdeasSetFun: async () => {
        // 接口请求必须带上await
        if (state.SonAccountData.length < 1) {
          ElMessage({
            type: 'error',
            message: '请选择子账户',
          })
          return
        }
        if (state.campaignDtoData == undefined) {
          ElMessage({
            type: 'error',
            message: '先填写项目配置',
          })
          return
        }
        state.campaignDtoDataSmartBidType = state.campaignDtoData.smartBidType
        state.budgetOptimizeSwitch = state.campaignDtoData.budgetOptimizeSwitch
        // 请求标题组数据
        await selectGroupTitle().then((res) => {
          state.selectGroupTitleArr = res.data
        })
        // // 请求行业列表（创意分类）
        // await selectIndustry().then((res) => {
        //   state.selectIndustryArr = res.data
        // })
        // 必须在全部请求最后 打开抽屉
        console.log(state.campaignDtoData)
        state.LdeasSetOn = true
      },
      flushTitleFun: () => {
        selectGroupTitle().then((res) => {
          state.selectGroupTitleArr = res.data
        })
      },
      // 重置
      ResetPageFun: () => {
        location.reload()
      },

      // 接收项目配置
      AdPlanOn: false,

      // 项目配置
      AdPlanFun: () => {
        if (state.SonAccountData.length > 0) {
          state.AdPlanOn = true
          // getProductAvailables({ advertiserId: state.SonAccountData[0] }).then((res) => {
          //   state.getProductAvailablesData = res.data
          //   state.AdPlanOn = true
          // })
        } else {
          ElMessage({
            type: 'error',
            message: '请选择子账户',
          })
        }
      },
      // 保存发布
      createPlanFun: () => {
        state.buttonDisabled = true
        setTimeout(() => {
          // 启用按钮
          state.buttonDisabled = false
        }, 2000)
        createV2Plan({
          accountIds: state.SonAccountData,
          projectDto: state.campaignDtoData,
          promotionDto: state.creativeDto,
          modelName: state.campaignDtoData.modelName,
        }).then((res) => {
          ElMessage({
            type: 'success',
            message: '创建成功',
          })
        })
      },
      //定时发布
      scheduleCreateV2PlanFun:()=>{
        if(state.sTime==''||state.name==''){
          
          return   ElMessage({
            type: 'error',
            message: '不能为空',
          })
        }

        const datas = {
          accountIds: state.SonAccountData,
          projectDto: state.campaignDtoData,
          promotionDto: state.creativeDto,
          modelName: state.campaignDtoData.modelName,
          publishTime:state.sTime,
          name:state.name,
        }
        scheduleCreateV2Plan(datas).then((res)=>{
          state.setTimeAbleFun()
          ElMessage({
            type: 'success',
            message: '定时成功',
          })
        })
      },
    })
    // 获取定向包查询2数据
    const selectDirectionalNotPageFun = () => {
      selectDirectionalNotPage().then((res) => {
        state.optionsData = res.data
      })
    }

    selectDirectionalNotPageFun()
    // 获取优化目标
    getV2optimizedGoal().then((res) => {
      state.getV2optimizedGoalData = res.data
    })
    // 获取首选媒体数据（广告计划-头条
    const selectToutiaoMediaFun = () => {
      selectToutiaoMedia().then((res) => {
        state.toutiaoMedia = res.data
      })
    }

    selectToutiaoMediaFun()

    return {
      ...toRefs(state),
      selectDirectionalNotPageFun,
    }
  },
  methods: {
    aaa: () => {
      console.log(
        '%c [  ]-434',
        'font-size:13px; background:pink; color:#bf2c9f;',
        this
      )

      const myButton = this.$refs.myButton

      // 在点击事件中调用 blur() 方法
      myButton.blur()
      state.createPlanFun()
    },
  },
}
</script>
<style lang="scss" scoped>
.toutiaoAddModelcontent {
  margin-top: 20px;
}
.toutiaoAddModel-carditem {
  width: 100%;
  font-size: 14px;
  border: 1px solid #e2e6f0;
  border-radius: 4px;
  margin-top: 0 !important;
  margin-right: 20px;
}
.defineHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f7fa;
  border-radius: 3px 3px 0 0;
  color: #282c33;
  padding: 12px 16px;
  box-sizing: border-box;
}
.defineBody {
  width: 100%;
  height: 400px;
  padding: 6px 12px;
  overflow: auto;
  box-sizing: border-box;
}
.mandatory {
  height: 338px;
  max-height: 338px;
}
.settingBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  .s_label {
    width: max-content;
  }
  .s_text {
    max-width: 60%;
    word-break: break-all;
  }
}
.mandatorybox {
  margin-top: 10px;
}
.tHead {
  font-size: 16px;
  font-weight: 600;
}
.toutiaoAddModelBtn {
  padding-top: 20px;
  text-align: right;
  justify-content: flex-end;
  >div{
   margin-left: 50px;
  }
}

.toutiaoAddModelTitleTop {
  > div {
    margin-right: 10px;
  }
}

.item {
  padding-top: 10px;
}

.addTitleBtn {
  justify-content: flex-end;
  > div {
    margin-left: 40px;
  }
}
</style>
